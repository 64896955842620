import { RetailItem, RetailItemPropertyType } from '../../client';
import { isBrowser } from '../utils';

type UserTraits = { [name: string]: any };

export const getAnalytics: () =>
  | SegmentAnalytics.AnalyticsJS
  | undefined = () => {
  return window.analytics;
};

export const getGeneralPeriodFromRetailItem = (
  retailItem: RetailItem,
): string | null => {
  const allMetaData = retailItem.objects?.reduce((acc: any, curr: any) => {
    return [...acc, ...curr.metadata];
  }, []);

  const generalPeriod = allMetaData?.find((metadata) => {
    return metadata.key === 'Period - General';
  });

  return generalPeriod?.value?.stringValue;
};

export const isAnalyticsTrackingEnabled = (): boolean => {
  if (isBrowser() && window) {
    if (!window?.consentManager?.inEU()) {
      return true;
    }
    const trackingPreferencesStr =
      window?.localStorage?.getItem('ajs_user_traits');

    if (trackingPreferencesStr) {
      const trackingPreferencesObject = JSON.parse(trackingPreferencesStr) as
        | UserTraits
        | undefined;

      if (
        trackingPreferencesObject?.['customTrackingPreferences']?.[
          'marketingAndAnalytics'
        ]
      )
        return true;
    }
  }
  return false;
};

export const propertyTypeToSaleType = (
  properyType: RetailItemPropertyType,
): string | undefined => {
  switch (properyType) {
    case RetailItemPropertyType.PeerToPeer:
      return 'retail';
    case RetailItemPropertyType.Marketplace:
      return 'retail';
    case RetailItemPropertyType.PrivateSaleExhibition:
      return 'private-sales';
    case RetailItemPropertyType.LiveAuctions:
      return 'lot';
    case RetailItemPropertyType.OnlineAuctions:
      return 'lot';
    case RetailItemPropertyType.TimeBasedSale:
      return 'lot';
    default:
      return undefined;
  }
};

export const propertyTypeToContentType = (
  properyType: RetailItemPropertyType,
): string | undefined => {
  switch (properyType) {
    case RetailItemPropertyType.PeerToPeer:
      return 'Buy Now';
    case RetailItemPropertyType.Marketplace:
      return 'Buy Now';
    case RetailItemPropertyType.PrivateSaleExhibition:
      return 'Private Sale';
    case RetailItemPropertyType.LiveAuctions:
      return 'Lot';
    case RetailItemPropertyType.OnlineAuctions:
      return 'Lot';
    case RetailItemPropertyType.TimeBasedSale:
      return 'Lot';
    default:
      return undefined;
  }
};

export const waysToBuyToSaleType = (wayToBuy: string) => {
  switch (wayToBuy) {
    case 'buyNow':
      return 'retail';
    case 'private':
      return 'private-sales';
    case 'bid':
      return 'lot';
    default:
      return undefined;
  }
};

export const waysToBuyToContentType = (wayToBuy: string) => {
  switch (wayToBuy) {
    case 'buyNow':
      return 'Buy Now';
    case 'private':
      return 'Private Sale';
    case 'bid':
      return 'Lot';
    default:
      return undefined;
  }
};
